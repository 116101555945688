import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledH2 = styled.h2`
  font-weight: 600;
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`
const StyledButton = styled.button`
font-weight: 600;
  font-size: 18px;
  padding: 10px 25px;
`

const HomeIntroSection = () => {
  return (
    <div className="bg-white w-full 2xl:max-w-screen-xl mx-auto">
      <div className="w-full mx-auto pb-16 px-6 xl:px-20">
        <StyledH2 className="text-site-green mb-3">Welcome to Brothers Nursery, Inc.</StyledH2>

        <p className="font-body italic text-lg mb-3">We at Brothers Nursery, Inc. strive to grow the best plants in the Southern California region. Our commitment is to provide the finest, most dependable and honest service to all of our customers. We pride ourselves in our wide selection of beautiful ornamental outdoor plants, trees and shrubs.</p>

        <p className="font-body italic text-lg mb-3">We strongly support and believe that a green environment is a healthier place to live and raise a family. For this reason our main priority is to care for our industry, our community, our customers and our employees. By growing the best quality material, supporting local projects, beautifying streetscapes, providing outstanding customer service and creating a safe work environment, we are helping to create that green environment.</p>

        <p className="font-body italic text-lg mb-3">We serve contractors, landscapers, retail and wholesale nurseries, and many others with landscaping needs.</p>

        <p className="font-body text-lg mb-3">We offer an extensive delivery area. Please contact our knowledgeable sales department for your wholesale plant needs, and they will be honored to assist you.</p>

        <div className="w-full grid grid-col-1 md:grid-cols-3 gap-6 mx-auto mt-10">
          <div>
            <StaticImage
              alt="Plants"
              src={
                "../../images/sections/plants.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={380}
            />

            <StyledH2 className="font-body text-site-green mb-2 mt-4">Plants</StyledH2>

            <p className="font-body text-base">Our plants with their delightful and exquisite colors can enhance any landscape. We carry many beautiful evergreens, flowering, drought-resistant, heat-tolerant, California Natives, and many more water-wise plants. We inventory popular but practical varieties in several sizes.</p>

            <Link to="/plants">
              <StyledButton className="w-full bg-site-green-dark hover:bg-site-green font-body text-white uppercase text-center mt-6">View Our Plants</StyledButton>
            </Link>
          </div>

          <div>
            <StaticImage
              alt="Trees"
              src={
                "../../images/sections/trees.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={380}
            />

            <StyledH2 className="font-body text-site-green mb-2 mt-4">Trees</StyledH2>

            <p className="font-body text-base">We have a substantial selection of magnificent evergreen, blooming, fruit, fruitless, deciduous, drought-resistant, heat-tolerant trees… and many more. Our specimens are available in 15-gallon containers and the 24”and 36” box sizes. Larger sizes are sometimes available by special order.</p>

            <Link to="/trees">
              <StyledButton className="w-full bg-site-green-dark hover:bg-site-green font-body text-white uppercase text-center mt-6">View Our Trees</StyledButton>
            </Link>
          </div>

          <div>
            <StaticImage
              alt="Shrubs"
              src={
                "../../images/sections/shrubs.jpg"
              }
              formats={["auto", "webp", "avif"]}
              width={380}
            />

            <StyledH2 className="font-body text-site-green mb-2 mt-4">Shrubs</StyledH2>

            <p className="font-body text-base">Our flowering, green and variegated shrubs easily capture anyone’s attention! We inventory a large variety of hedge forming, ground covering, California Natives, plus many heat-tolerant, drought-resistant and various other specimens in these sizes: one-gallon, five-gallon and fifteen-gallon.</p>

            <Link to="/shrubs">
              <StyledButton className="w-full bg-site-green-dark hover:bg-site-green font-body text-white uppercase text-center mt-6">View Our Shrubs</StyledButton>
            </Link>
          </div>
        </div>

        <div className="mt-4">
          <StyledH2 className="text-site-green mb-2">Our History</StyledH2>

          <p className="font-body text-base">Our roots can be traced to that exact moment in time when Pedro Mercado decided to grow plants in his mother’s backyard in the San Gabriel Valley as a side hobby.  His idea of starting his own business soon bloomed and a couple of years later he invited his brother Jose to join him, which inspired the name of our company, Brothers Nursery. We are family owned and have operated continuously since 1983. Our business has grown from a residential backyard garden to a vibrant company with five beautiful and thriving locations in San Bernardino, Riverside and San Diego counties.</p>
        </div>
      </div>
    </div>
  )
}

export default HomeIntroSection