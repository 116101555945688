import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import Layout from "../components/layout/layout"
import HomeIntroSection from "../components/sections/home-intro-section"
import Seo from "../components/seo"

const HomeHeroSection = loadable(() =>
  pMinDelay(import('../components/sections/home-hero-section'), 2000),{
    fallback:
    <div className="bg-white w-full 2xl:max-w-screen-xl mx-auto">
      <div className="w-full mx-auto pb-5 px-6 xl:px-20">
        <StaticImage
          alt="First Home Hero Background"
          src={
            "../images/backgrounds/hero-one.jpg"
          }
          formats={["auto", "webp", "avif"]}
          width={1180}
        />
      </div>
    </div>
  }
)

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <HomeHeroSection />

    <HomeIntroSection />
  </Layout>
)

export default IndexPage
